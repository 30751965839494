import { useState, useEffect } from "react";
import "./Termin.css";

export default function Termin({ isLoggedIn }) {
  const [meeting, setMeeting] = useState({
    topic: "",
    date: "",
    location: "",
    notes: "",
  });

  const [savedMeeting, setSavedMeeting] = useState(null);
  const [showPlanner, setShowPlanner] = useState(false);

  // Fetch saved meeting from backend
  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const response = await fetch("http://192.168.0.41:5000/get-meeting");
        console.log("Response status:", response.status); // Log the response status
        console.log("Response ok:", response.ok); // Log the response.ok property

        if (!response.ok) {  // Check for HTTP errors
          const errorData = await response.json(); // Try to get error details
          throw new Error(errorData.error || `HTTP error! status: ${response.status}`); // Throw error with details
        }
        const data = await response.json();
        setSavedMeeting(data);
      } catch (error) {
        console.error("Error fetching meeting:", error); // Log detailed error
        // Consider displaying an error message to the user:
        // alert("Error fetching meeting data. Please try again later.");
      }
    };

    fetchMeeting(); // Call the function immediately
  }, []); // Empty dependency array: fetch only once on mount
  // Handle input changes
  const handleChange = (e) => {
    setMeeting({ ...meeting, [e.target.name]: e.target.value });
  };

  // Save meeting to backend
  const saveMeeting = async () => {
    try {
      const response = await fetch("http://192.168.0.41:5000/save-meeting", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(meeting),
      });

      if (response.ok) {
        const data = await response.json(); // Get the saved data from the server.
        setSavedMeeting(data); // Update savedMeeting with the actual data from the server.
        setMeeting({ topic: "", date: "", location: "", notes: "" });
        setShowPlanner(false);
      } else {
        const errorData = await response.json(); // Try to get error details from the server.
        console.error("Failed to save meeting:", errorData); // Log the error from the server.
        alert("Failed to save meeting. Please check the console for details."); // Alert the user.
      }
    } catch (error) {
      console.error("Error saving meeting:", error);
      alert("An error occurred while saving. Please check the console."); // Alert the user.
    }
  };

  return (
    <div className="container">
      <h1>Meeting Schedule</h1>
      {/* ✅ Show "Schedule Meeting" button only if logged in */}
      {isLoggedIn && <button onClick={() => setShowPlanner(true)}>Termin Plannen</button>}
      
      {showPlanner && (
        <div className="form-container">
          <label>Meeting Topic</label>
          <input name="topic" value={meeting.topic} onChange={handleChange} placeholder="Enter meeting topic" />

          <label>Date & Time</label>
          <input type="datetime-local" name="date" value={meeting.date} onChange={handleChange} />

          <label>Location</label>
          <input name="location" value={meeting.location} onChange={handleChange} placeholder="Enter location" />

          <label>Notes</label>
          <textarea name="notes" value={meeting.notes} onChange={handleChange} placeholder="Additional notes" />

          <button onClick={saveMeeting}>Speichern</button>
        </div>
      )}

      <h2>Nächster Termin</h2>
      {savedMeeting && Object.keys(savedMeeting).length > 0 && Object.values(savedMeeting).some(value => value !== "") ?(
        <div className="meeting-details">
          <strong>{savedMeeting.topic}</strong>  
          <p><strong> Datum: {savedMeeting.date.replace("T", " um ")}</strong></p>
          {savedMeeting.date} in {savedMeeting.location}
          <p>{savedMeeting.notes}</p>
        </div>
      ) : (
        <p>Kein Meeting geplant.</p>
        
      )}
    </div>
  );
}

import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import CloseIcon from '../assets/icons8-close.svg';  // Import the close icon image
import HamburgerIcon from '../assets/icons8-menu.svg';      // Import the hamburger icon image    
// import HomeIcon from '../assets/icons8-zuhause-24.png';          // Import the home icon image
import { Button } from './Button';
import './Navbar.css'


function Navbar() {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handleClick = () =>  setClick(!click);
    const closeMobileMenu = () => setClick(false)


    const showButton = () => {
      if(window.innerWidth <= 960){
        setButton(false);
      } else {
        setButton(true);
      }
    };
    window.addEventListener('resize', showButton)

    useEffect(()=> {
      showButton()
    },[])

  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
               <Link to="/" className="navbar-logo" onClick={closeMobileMenu}> 
               U40{/* <img className='home' src={HomeIcon}/> */}
               </Link> 
               <div className='menu-icon' onClick={handleClick}>
                <img src={click ?   CloseIcon : HamburgerIcon} className={click ? 'fas fa-times' : 'fa-solid fa-bars'} alt="anything"/>
               </div>
               <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/kontakt'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Kontakt
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/treffen'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Treffen
              </Link>
            </li>

            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
import React from 'react';
import '../../App.css';
import Img1 from '../../images/map.png';

export default function Treffen() {
  const TreffenDetails = {
    meeting_point: "Jeden 1.Sonntag im Monat",
    time: "14:30",
    location: "Gemeindehaus der Petruskirche",
    address: "70794 Filderstadt-Bernhausen Talstraße 21",
    maps_link: "https://www.google.com/maps/search/?api=1&query=70794+Filderstadt-Bernhausen+Talstraße+21"
  };
  return (
    <div className="treffen-Container">
      <h1 className="kontakt">Treffen</h1>
      <div className="location-details">
      <p><strong> {TreffenDetails.meeting_point}</strong> </p>
        {/* <p><strong></strong> {TreffenDetails.name}</p> */}
        <p><strong>Um:</strong> {TreffenDetails.time}</p>
        <p><strong>Im:</strong> {TreffenDetails.location}</p>
        <p><strong>In:</strong> {TreffenDetails.address}</p>
        <p>

        <a href={TreffenDetails.maps_link} target="_blank" rel="noopener noreferrer">
        → Adresse in Google-Karte anzeigen
          </a>
        </p>
        <div className="image-container">
        <img class="map" src={Img1} alt="Map" />
        </div>
      </div>
    </div>
  );
}

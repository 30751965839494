import './App.css';
import Navbar from './components/Navbar';
import React , { useState } from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Kontakt from './components/pages/Kontakt';
import Treffen from './components/pages/Treffen';
import SignUp from './components/pages/SignUp';
import AboutUS from './components/pages/Aboutus';
import Termin from './components/pages/Termin';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Lift state up
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/kontakt' element={<Kontakt />} />
          <Route path='/treffen' element={<Treffen />} />
          <Route path='/sign-up' element={<SignUp setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path='/termin' element={<Termin isLoggedIn={isLoggedIn} />} />
          <Route path='/aboutus' element={<AboutUS />} />
      </Routes>
    
    </Router>
    
    </>
    
  );
}

export default App;

import React from 'react';
import '../../App.css';
import flyer1 from '../../images/Flyer1.png';
import flyer2 from '../../images/Flyer2.png';

export default function aboutUs() {
  return (
    <div className="aboutUs-container">
      <h1> Über uns</h1>
      <>
      <div className="image-section" style={{ backgroundImage: `url(${flyer1})` }}></div>
      <div className="image-section" style={{ backgroundImage: `url(${flyer2})` }}></div>
      </>
    </div>
  );
}
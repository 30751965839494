import React from 'react';
import './Button.css'
import {Link} from 'react-router-dom'

const STYLE = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    children,
    type,
    onclick, 
    buttonStyle, 
    buttonSize,
    link,
}) => {
    const checkButtonStyle = STYLE.includes(buttonStyle) ? buttonStyle : STYLE[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    const checkLink = link ? link : '/sign-up'

    return(
        <Link to={`${checkLink}`} className='btn-mobile'>
            <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onclick}
            type={type}>
                {children}
            </button>
        </Link>
    )
        
    };
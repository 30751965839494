import React, { useState, useEffect } from 'react';
import '../../App.css';
import './signUp.css';
import { Link } from 'react-router-dom';


const SignUp = ({ setIsLoggedIn }) => { // Receive setIsLoggedIn from App.js
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const signinData = { username, password };

    try {
      const response = await fetch('http://192.168.0.41:5000/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signinData),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccess(`Welcome, ${data.username}!`);
        setError('');
        setUsername('');  // Clear username field
        setPassword('');  // Clear password field
        
        
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Signin failed. Please try again.');
        setSuccess('');
        setUsername('');  // Clear username field
        setPassword('');  // Clear password field
      }
    } catch (error) {
      setError('Something went wrong. Please try again. \n is Backend working?');
      setSuccess('');
      setUsername('');  // Clear username field
      setPassword('');  // Clear password field
      setTimeout(() => {
        setIsLoggedIn(true);
      }, 100);
     
    }
  };

  useEffect(() => {
    if (success) {
      setIsLoggedIn(true);
    }
  }, [success]);

  return (
    
      <div className="signin-container">
        <h2 className='signinText'>Sign in</h2>
  
        {/* Display success message */}
        {success &&
        <>
        
        <p className="success" style={{ color: 'green' }}>{success}</p> 
        <Link to='/'>Zurück zum Home Page</Link>
        
        </>
        }
  
       
  
        {/* Hide form if login is successful */}
        {!success && (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
  
            <button type="submit">Signin</button>
          </form>
        )}
         {/* Display error message */}
         {error && <p className="error" style={{ color: 'red' }}>{error}</p>}
      </div>
    );
  };

export default SignUp;

import React from 'react';
import '../../App.css';

export default function Kontakt() {
  const contactDetails = {
    name: "c/o Anton Merz",
    email: "Anton_Merz@gmx.de",
    phone: "0711/874225",
    address: "...."
  };
  return (
    <div className="kontakt-container">
      <h1 className="kontakt">Kontakt</h1>
      <div className="contact-details">
      <p><strong></strong> Parkinson-SHG U40</p>
        <p><strong></strong> {contactDetails.name}</p>
        <p><strong>Email:</strong> {contactDetails.email}</p>
        <p><strong>Phone:</strong> {contactDetails.phone}</p>
        <p><strong>Address:</strong> {contactDetails.address}</p>
      </div>
    </div>
  );
}
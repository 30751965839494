import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import AOK_logo from '../images/AOK_logo.png';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        {/* <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> */}
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/aboutus'>Mehr über uns</Link>
            
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/kontakt'> Kontakt</Link>            
          </div>

          <div class='footer-link-items'>
            <h2>Useful Links</h2>            
            <a href="https://www.kiss-stuttgart.de/" target="_blank" rel="noopener noreferrer">Kiss Stuttgart</a>
            <a href="https://www.dpv-bundesverband.de/baden-wuerttemberg/start" target="_blank" rel="noopener noreferrer"> Deutsche Parkinson Verein</a>
            <a href="https://www.dpv-bundesverband.de/stuttgart/start" target="_blank" rel="noopener noreferrer">Parkinson Verein Stuttgart</a>
            <a href="https://parki-stgt.de/" target="_blank" rel="noopener noreferrer">DPV Selbsthilfegruppe Stuttgart</a>
            
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Termine</h2>
            <Link to='/treffen'> regelmäßiger Treffpunkt</Link>
            <Link to='/termin'>Andere Termine</Link>
            
          </div>
          <div class='footer-link-items'>
            <h2>Documents</h2>
            <Link to='/document'>Nützliche Dokumente</Link>
            
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              U40
              {/* <i class='fab fa-typo3' /> */}
            </Link>
          </div>
          <div class="sponsor">
            
              <h1 class='website-rights'>Gefördert nach §20c SGB v mit Mitteln der Regionalen Fördergemeinschaft Neckar-Fils </h1>
              <a class="sponsor-link" href="https://www.aok.de/pk/bw" target="_blank" rel="noopener noreferrer">
              <img class="aok_logo" src={AOK_logo} alt="AOK Logo" />
            </a>
          </div>
         
          <div class='social-icons'>
            {/* <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
